import React, { useEffect, useState } from 'react'
import apiEndPoints from '../../utils/apiEndPoints'
import { apiCall } from '../../utils/httpClient'
import BranchComponent from './component/BranchComponent'

export default function Branch(props) {
    const [branch, setbranch] = useState([])
    const [message, setmessage] = useState()
    
    const branchDetail=async()=>{
        const params={branch_id:props.match.params.id}
        const response=await apiCall('POST',apiEndPoints.BRANCH,params)
        if(response.status===200)
        {
            setbranch(response.data.data)
        }
    }
    useEffect(() => {
        branchDetail()
    }, [])
    const UpdateBranch=async()=>{
        console.log("branch" , branch)
        const response=await apiCall('POST',apiEndPoints.UPDATE_BRANCH,branch)
        console.log(response, "response")
        if(response.status==200)
        {
            setmessage(response.data.message)
        }
    }
    console.log("branch",branch)
    return (
        <BranchComponent branch={branch} setbranch={setbranch} UpdateBranch={UpdateBranch} message={message}/>
    )
}
