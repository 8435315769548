import React from 'react';
import logo from '../../../assets/images/attachment-1.jpg';
import useForm from 'react-hook-form'
const Login = (props) => {
    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const onSubmit = data => {
        if (data !== '') {
            props.loginAuth()
        } else {
            errors.showMessages();
        }
    };
    
    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center"><img src="/assets/images/auditor.png" alt="" /></div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>LOGIN</h4>
                                                    <h6>Enter your Email and Password </h6>
                                                </div>
                                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                                  {props.errorMessage?  <div style={{color:"red"}}>{props.errorMessage}</div> : ''}
                                                    <div className="form-row">
                                                        <label htmlFor="validationCustom01">Email</label>
                                                        <input
                                                            className="form-control"
                                                            name="email"
                                                            type="text"
                                                            placeholder="Email"
                                                            ref={register({ required: true })}
                                                            onChange={(e) =>props.setEmail(e.target.value)}
                                                        />
                                                        <span>{errors.email && ' Email is required'}</span>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                    <div className="form-row">

                                                        <label htmlFor="validationCustom02">Password</label>
                                                        <input
                                                            className="form-control"
                                                            name="password"
                                                            type="password"
                                                            placeholder="password"
                                                            ref={register({ required: true })}
                                                            onChange={(e) => props.setPassword(e.target.value)}
                                                        />
                                                        <span>{errors.password && 'Password is required'}</span>
                                                        <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                    <div className="form-group">
                                                    </div>
                                                    <button className="btn btn-primary" type="submit">Login</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};
export default Login;