import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";
import apiEndPoints from "../../../utils/apiEndPoints";

const Addbranch = () => {
  const [inputData, setInputData] = useState({
    branch_code:"",
    branch_name: "",
    city: "",
    district: "",
    state: "",
    status: "",
    rbi_class: "",
    cluster: "",
    zone: "",
    circle: "",
    region: "",
    bbh_region: "",
    bbh_head: "",
    bbh_emp_code: "",
    regional_head: "",
    regional_emp_code: "",
    zonal_head: "",
    zonal_emp_code: "",
    circle_head: "",
    circle_head_empcode: "",
    circle_state_head: "",
    circle_state_empcode: "",
    branch_manager: "",
    bm_emp_code: "",
    rim: "",
    branch_address: "",
    pin_code: "",
    rmh_name: "",
    rmm_name: "",
    rmm_id: "",
    rmm_email: "",
    rmm_phone: "",
    ah_name: "",
    ah_empcode: "",
    ah_mail: "",
    ah_phone: "",
    branch_type: 0,
    digital_screen: "",
  });
  const [cityList, setcityList] = useState([]);
  var CityName = [];
  const CityList = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const params = { type: 1 };
    const response = await apiCall("POST", apiEndPoints.City_List, params);
    if (response.status === 200) {
      setcityList(response.data.data);
    }
  };
  useEffect(() => {
    CityList();
    DisplayCityList();
  }, []);
  const DisplayCityList = async () => {
    CityName = console.log("cityname", CityName);
  };

  const branchDataInsert = async () => {
    console.log("inputData", inputData);
    try {
      const userToken = localStorage.getItem("AuthToken");
      setDefaultHeader("token", userToken);
      const response = await apiCall(
        "POST",
        apiEndPoints.UPDATE_BRANCH,
        inputData
      );
      console.log(response, "GETETETE");
      if (response.status === 200) {
        window.location.href = "/dashboard/branchlist";
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <Breadcrumb title="Branch" parent="Add Branch" />
      <div className="container-fluid">
        <form className="form theme-form">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <div className="form-group row mb-2" >
                  <label className="col-sm-3 col-form-label">Branch Code</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Branch Code"
                      value={inputData.branch_code}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          branch_code: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Name</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Branch Name"
                      value={inputData.branch_name}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          branch_name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-3 col-form-label">Address</label>
                  <div className="col-sm-9">
                    <textarea
                      className="form-control"
                      rows="5"
                      cols="5"
                      placeholder="Adress"
                      value={inputData.branch_address}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          branch_address: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">City</label>
                  <div className="col-sm-9">
                    <select
                      id=""
                      className="form-control"
                      value={inputData.city}
                      onChange={(e) =>
                        setInputData({ ...inputData, city: e.target.value })
                      }
                    >
                      {cityList.map((value) => (
                        <option value={value.id}>{value.city_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">District</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="District"
                      value={inputData.district}
                      onChange={(e) =>
                        setInputData({ ...inputData, district: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-3 col-form-label">Pincode</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Pincode"
                      value={inputData.pin_code}
                      onChange={(e) =>
                        setInputData({ ...inputData, pin_code: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">State</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="State"
                      value={inputData.state}
                      onChange={(e) =>
                        setInputData({ ...inputData, state: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Status</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Status"
                      value={inputData.status}
                      onChange={(e) =>
                        setInputData({ ...inputData, status: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">RBI Class</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="RBI Class"
                      value={inputData.rbi_class}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          rbi_class: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Cluster</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Cluster"
                      value={inputData.cluster}
                      onChange={(e) =>
                        setInputData({ ...inputData, cluster: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Zone</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Zone"
                      value={inputData.zone}
                      onChange={(e) =>
                        setInputData({ ...inputData, zone: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Circle</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Circle"
                      value={inputData.circle}
                      onChange={(e) =>
                        setInputData({ ...inputData, circle: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Region</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Region"
                      value={inputData.region}
                      onChange={(e) =>
                        setInputData({ ...inputData, region: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Manager</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Manager"
                      value={inputData.branch_manager}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          branch_manager: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Manager Employe Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Manager Employe Code"
                      value={inputData.bm_emp_code}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          bm_emp_code: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Manager Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Manager Number"
                      value={inputData.rim}
                      onChange={(e) =>
                        setInputData({ ...inputData, rim: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Head</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Head"
                      value={inputData.bbh_head}
                      onChange={(e) =>
                        setInputData({ ...inputData, bbh_head: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Head Region</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Head Region"
                      value={inputData.bbh_region}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          bbh_region: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Head Employe Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Head Employe Code"
                      value={inputData.bbh_emp_code}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          bbh_emp_code: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Regional Head
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Regional Head"
                      value={inputData.regional_head}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          regional_head: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Regional Head Employe Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Regional Head Employe Code"
                      value={inputData.regional_emp_code}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          regional_emp_code: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Zonal Head</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Zonal Head"
                      value={inputData.zonal_head}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          zonal_head: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Zonal Head Employe Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Zonal Head Employe Code"
                      value={inputData.zonal_emp_code}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          zonal_emp_code: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Circle Head</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Circle Head"
                      value={inputData.circle_head}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          circle_head: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Circle Head Employe Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Circle Head Employe Code"
                      value={inputData.circle_head_empcode}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          circle_head_empcode: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Circle State Head
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Circle State Head"
                      value={inputData.circle_state_head}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          circle_state_head: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Circle State Head Employe Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Circle State Head Employe Code"
                      value={inputData.circle_state_empcode}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          circle_state_empcode: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label"> RMH Name</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="RMH Name"
                      value={inputData.rmh_name}
                      onChange={(e) =>
                        setInputData({ ...inputData, rmh_name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label"> RMM Name</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="RMM Name"
                      value={inputData.rmm_name}
                      onChange={(e) =>
                        setInputData({ ...inputData, rmm_name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">RMM ID</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="RMM ID"
                      value={inputData.rmm_id}
                      onChange={(e) =>
                        setInputData({ ...inputData, rmm_id: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">RMM Email</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="RMM Email"
                      value={inputData.rmm_email}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          rmm_email: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">RMM Phone</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="RMM Phone"
                      value={inputData.rmm_phone}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          rmm_phone: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">AH Name</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="AH Name"
                      value={inputData.ah_name}
                      onChange={(e) =>
                        setInputData({ ...inputData, ah_name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    AH Employe code
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="AH Employe code"
                      value={inputData.ah_empcode}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          ah_empcode: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">AH Email</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="AH Email"
                      value={inputData.ah_mail}
                      onChange={(e) =>
                        setInputData({ ...inputData, ah_mail: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">AH Phone</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="AH Phone"
                      value={inputData.ah_phone}
                      onChange={(e) =>
                        setInputData({ ...inputData, ah_phone: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    {" "}
                    Branch Type
                  </label>
                  <div className="col-sm-9">
                    <select
                      id=""
                      className="form-control"
                      value={inputData.branch_type}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          branch_type: Number(e.target.value),
                        })
                      }
                    >
                      <option value={0}>Old Branch</option>
                      <option value={1}>New Branch</option>
                    </select>
                    {/* <input className="form-control" type="text"
                                        placeholder="Branch Type"
                                        value={inputData.branch_type}
                                        onChange={e => setInputData({ ...inputData, branch_type: e.target.value })}
                                    /> */}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Digital Screen
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Digital Screen"
                      value={inputData.digital_screen}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          digital_screen: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="col-sm-9 offset-sm-3">
              <button
                className="btn btn-primary mr-1"
                type="button"
                onClick={() => branchDataInsert()}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Addbranch;
