import React, { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";
import Question from './pages/Question'
import Branch from './pages/Branch'
import Profile from './pages/Profile'

// screen 
import EndPoints from './utils/apiEndPoints'
import { apiCall, setDefaultHeader } from './utils/httpClient';
// import { GlobalSpinnerContext } from './component/Context/GlobalSpinnerContext'
import { AuthContext } from './components/Context/context';
import LoginScreen from './pages/Login';
import Dashboard from './pages/Dashboard';
import { UserProvider } from './components/Context/UserContext';
import BranchList from './pages/Dashboard/BranchList'
import QuestionList from './pages/Dashboard/QuestionList/QuestionList'
import AuthState from './AuthState'
import Addbranch from './pages/Dashboard/Addbranch/Addbranch'
import AddQuestion from './pages/Dashboard/Addquestion/AddQuestion';
import Report from './pages/Report'
import ChangePassword from './pages/ChangePassword';
import AddEmploye from './pages/Dashboard/AddEmploye/AddEmploye';
import EmployeList from './pages/Dashboard/EmployeList/EmployeList';
import AddBranchEmployee from './pages/Dashboard/Addbranch/AddBranchEmployee';
// import Employe from './pages/Employe';
//firebase Auth
function Root() {
  const initialLoginState = {
    isLoading: true,
    userToken: null,
  };
  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          userToken: null,
          isLoading: false,
        };
    }
  };
  const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState);
  const authContext = React.useMemo(() => ({
    signIn: async (Token) => {
      const userToken = Token;
      try {
        await localStorage.setItem('AuthToken', userToken);
        await setDefaultHeader('token', userToken)
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'LOGIN', token: userToken });
    },
    signOut: async (user_id) => {
      try {
        await localStorage.removeItem('AuthToken');
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'LOGOUT' });
    },
    signUp: () => {
    }
  }), []);
  React.useEffect(() => {
    getToken()
    setTimeout(async () => {
      let userToken;
      userToken = null;
      try {
        userToken = await localStorage.getItem('AuthToken');

      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'RETRIEVE_TOKEN', token: userToken });
    }, 1000);

  }, []);
  async function getToken() {
    try {
      const Auth = await localStorage.getItem('AuthToken');
      if (Auth != null) {
        await setDefaultHeader('token', Auth)
      } else {
        const { data } = await apiCall('get', EndPoints.JWTTOKEN)
        await setDefaultHeader('token', data.token)
        await localStorage.setItem('token', data.token);

      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="App">
      <UserProvider>
        <AuthContext.Provider value={authContext}>
          <BrowserRouter basename={'/'}>
            <ScrollContext>
                  <Switch>
                  <Route exact path={'/'} component={LoginScreen} />
                  <Fragment>
                    <App>
                      <Route exact path={'/dashboard'} component={AuthState(Dashboard)} />
                      <Route path={'/dashboard/branchlist'} component={AuthState(BranchList)} />
                      <Route path={'/dashboard/addBranch'} component={AuthState(Addbranch)} />
                      <Route path={'/dashboard/addBranchEmployee'} component={AuthState(AddBranchEmployee)} />
                      <Route path={'/dashboard/employeList'} component={AuthState(EmployeList)} />
                      {/* <Route path={'/dashboard/employe/:id'} component={AuthState(Employe)}/> */}
                      <Route path={'/dashboard/addEmploye'} component={AuthState(AddEmploye)} />
                      <Route path={'/dashboard/questionlist'} component={AuthState(QuestionList)} />
                      <Route path={'/dashboard/addquestion'} component={AuthState(AddQuestion)} />
                      <Route path={'/dashboard/question/:id'} component={AuthState(Question)}/>
                      <Route path={'/dashboard/Branch/:id'} component={AuthState(Branch)}/>
                      <Route path={'/dashboard/profile'} component={AuthState(Profile)}/>
                      <Route path={'/dashboard/report'} component={AuthState(Report)}/>
                      <Route path={'/dashboard/changepassword'} component={AuthState(ChangePassword)}/> 
                    </App>
                  </Fragment>
                  </Switch>
            </ScrollContext>
          </BrowserRouter>
        </AuthContext.Provider>
      </UserProvider>
    </div>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();