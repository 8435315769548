import React,{useEffect} from 'react';
import DashboardScreen from './component/dashboard';

const DashboardView = ()=>{
    return(
        <DashboardScreen/>
    )

}
export default DashboardView;
