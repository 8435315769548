import {
    Flag,
    Home,
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Employee', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/dashboard/employeList', title: 'Employee List', type: 'link' },
            { path: '/dashboard/addEmploye', title: 'Add Employee', type: 'link' },
        ]
    },
    {
        title: 'Branch', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/dashboard/branchlist', title: 'Branch List', type: 'link' },
            { path: '/dashboard/addBranch', title: 'Add Branch', type: 'link' },
            { path: '/dashboard/addBranchEmployee', title: 'Add Branch Employee', type: 'link' },
        ]
    },
    {
        title: 'Question', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/dashboard/questionlist', title: 'Question List', type: 'link' },
            { path: '/dashboard/addquestion', title: 'Add Question', type: 'link' },
        ]
    },
    {
        title: 'Report', icon: Flag, type: 'link', badgeType: 'primary', active: false,path:'/dashboard/report'
    },
]

