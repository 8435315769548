import React, { useState } from "react";
import apiEndPoints from "../../utils/apiEndPoints";
import ChangePassword from "./component/ChangePassword";
import {apiCall} from '../../utils/httpClient'
export default function Index() {
  const [oldPass, setoldPass] = useState();
  const [newPass, setnewPass] = useState();
  const [confromPass, setconfromPass] = useState();
  const [message, setmessage] = useState()
  const handleChangePassword=async()=>{
    try {
        if(newPass!==confromPass)
        {
            setmessage("Please Check New Password")
        }else{
            const params={
                old_password:oldPass,
                new_password:newPass
            }
            const {data}=await apiCall("POST",apiEndPoints.CHANGE_PASS,params)
            setmessage(data.message)
        }
    } catch (error) {
        console.log(error)
    }
  }
  return (
    <ChangePassword
      oldPass={oldPass}
      setoldPass={setoldPass}
      newPass={newPass}
      setnewPass={setnewPass}
      confromPass={confromPass}
      setconfromPass={setconfromPass}
      handleChangePassword={handleChangePassword}
      message={message}
    />
  );
}
