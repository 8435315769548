import React, { useState } from 'react'
import Alert from '../../../components/Alert'
export default function ProfileComponent(props) {
    const { userData, setuserData, UpdateProfile, message } = props
    
    return (
        <div className="container-fluid">
            {
                message&&
                   <Alert message={message}/>
             
            }
            <form className="form theme-form">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        value={userData.name} onChange={e => setuserData({ ...userData, name: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Email</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        value={userData.email} onChange={e => setuserData({ ...userData, email: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Phone</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        value={userData.phone} onChange={e => setuserData({ ...userData, phone: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="col-sm-9 offset-sm-3">
                        <button className="btn btn-primary mr-1" type="button" onClick={() => UpdateProfile()}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
