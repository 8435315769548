import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { User, Mail, Lock, Settings, LogOut, Key } from 'react-feather';


const UserMenu = () => {
    const logOut=()=>{
        localStorage.removeItem('AuthToken')
        window.location.href='/'
    }
    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                <i className="fas fa-user-cog fa-2x"></i>
                    {/* <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" /> */}
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to="/dashboard/profile"><User />Edit Profile</Link></li>
                    <li><Link to="/dashboard/changepassword"><Key/>Change Password</Link></li>
                    <li><a href="#" onClick={()=>logOut()}><LogOut /> Log out</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;