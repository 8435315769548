export default {
    JWTTOKEN: '/tokenGenrate',
    SIGNIN: '/userAuth/adminLogin',
    QUESTION_LIST:'/question/getQuestion',
    Add_QUESTION:'/question/addEditQuestion',
    QUESTION:'/question/getQuestiondetail',
    BRANCH_LIST:'/service/getAllBranchList',
    BRANCH:'/service/getBranchDetails',
    UPDATE_BRANCH:'/service/editBranchDetails',
    PROFILE_UPDATE:'/userAuth/adminProfileUpdate',
    DELETE_BRANCH:'/service/deleteBranch',
    DELETE_QUESTION:'/service/deletequestion',
    City_List:'service/getBranchCity',
    GET_AUDIT_INFO:'audit/getaAuditinfo',
    CHANGE_PASS:'/userAuth/changePassword',
    EMPLYEEREGISTER:"/userAuth/addEmployee",
    GETEMPLOYEEROLEID:"/userAuth/getEmployeeRoleData",
    GETBRANCHNAME:"/service/getBranchName",
    GETEMPLOYEELISTDATA:'userauth/getEmployeeListData',
    UPDATEEMPLOYEEINBRANCH:'userauth/updateEmployeeInBranch',
    GENERATENEWREPORT :"audit/generateNewReport",
    UPDATEEMPPROFILE:"/userauth/updateEmpProfile",
    GETRMHAUDITINFO: "audit/getRmhAuditinfo",
    GETAHAUDITINFO: "audit/getAhAuditinfo"
}