import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = () => {
    const userData=JSON.parse(localStorage.getItem('userData'))
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <h6 className="mt-3 f-14">{userData.name}</h6>
                <p>{JSON.parse(localStorage.getItem('userData')).emp_role_id == 5 ? 'RMH': JSON.parse(localStorage.getItem('userData')).emp_role_id == 4 ?'AH':'Super Admin' } </p>
            </div>
        </Fragment>
    );
};

export default UserPanel;