import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/common/breadcrumb";
import Search from "../../../components/Search";
import apiEndPoints from "../../../utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";

export default function EmployeList() {
  const [search, setsearch] = useState("");
  const [searchArray, setsearchArray] = useState();
  const [employeList, setEmployeeList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [empDetailsScreen, setEmpDetailsScreen] = useState(false);

  useEffect(() => {
    GetEmpoyeList();
  }, []);

  const GetEmpoyeList = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const param = {
      employee_roal_type: "",
    };
    const response = await apiCall(
      "POST",
      apiEndPoints.GETEMPLOYEELISTDATA,
      param
    );

    console.log("response", response);
    if (response.status === 200) {
      setEmployeeList(response.data.data);
      setsearchArray(response.data.data);
    }
  };

  const columns = [
    {
      name: "Name of Employee",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Employee Code",
      selector: (row) => row.emp_code,
      sortable: true,
    },
    {
      name: "Employee Type",
      selector: (row) =>
        row.emp_role_id == 3
          ? "RMM"
          : row.emp_role_id == 4
          ? "AH"
          : row.emp_role_id == 5
          ? "RMH"
          : "",
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div style={{ display:"flex", justifyContent:"center" }}>
          {/* <Link to={`/dashboard/employe/${row.emp_id}`}>
            <i class="fas fa-eye"></i>
          </Link> */}
          <Link onClick={(e) => handleEmployeDetails(row)}>
            <i class="fas fa-eye"></i>
          </Link>
        </div>
      ),
    },
  ];

  const handleEmployeDetails = (employee) => {
    console.log("employedeatils", employee);
    setEmployeeDetails(employee);
    setEmpDetailsScreen(true);
  };

  const updateEmployeDetails = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    console.log("employeeDetails", employeeDetails);
    const params = {
      name: employeeDetails.name,
      emp_code: employeeDetails.emp_code,
      phone: employeeDetails.phone,
      branch_id: employeeDetails.branch_id,
    };
    const response = await apiCall(
      "POST",
      apiEndPoints.UPDATEEMPPROFILE,
      params
    );

    console.log("response", response);
    // if (response.status === 200) {
    //   setEmployeeList(response.data.data);
    //   setsearchArray(response.data.data);
    // }
  };

  return (
    <>
      {!empDetailsScreen ? (
        <div>
          <Breadcrumb title="Employee List" parent="Employee" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <Search
                      search={search}
                      setsearch={setsearch}
                      title={"Search By Employe Name"}
                    />
                  </div>
                  <div className="card-block row">
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={
                            employeList &&
                            employeList.filter((data) => {
                              if (search == "") return searchArray;
                              else if (
                                data.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                                return data;
                            })
                          }
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // ------------------------------------ view Employement Details -------------------
        <div>
          <Breadcrumb title="Employe Details" parent="Employe" />
          <div className="container-fluid">
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">
                        Employe Code
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Code"
                          value={employeeDetails.emp_code}
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">
                        Employe Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Name"
                          value={employeeDetails.name}
                          // onChange={(e) =>
                          //   setEmployeeDetails({
                          //     ...employeeDetails,
                          //     name: e.target.value,
                          //   })
                          // }
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">Email</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Email"
                          value={employeeDetails.email}
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">Phone</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          maxLength={10}
                          type="text"
                          placeholder="Phone"
                          value={employeeDetails.phone}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Employe Role
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Role"
                          value={
                            employeeDetails.emp_role_id == 3
                              ? "RMM"
                              : employeeDetails.emp_role_id == 4
                              ? "AH"
                              : employeeDetails.emp_role_id == 5
                              ? "RMH"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="col-sm-9 offset-sm-3">
                        <button
                          className="btn btn-primary mr-1"
                          type="button"
                          onClick={() => setEmpDetailsScreen(false)}
                        >
                          Back
                        </button>
                        {/* <button
                          className="btn btn-primary mr-1"
                          type="button"
                          onClick={updateEmployeDetails}
                        >
                          update
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
