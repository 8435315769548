import React, { useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
export default function QuestionComponent(props) {
  const { question, setquestion } = props;
  console.log("question", question);
  const handlequestion = (id) => {
    setquestion({
      ...question,
      go_question_id: id,
      yes_no_type: props.yes_no,
    });
  };
  const handleSearch = (text) => {
    // console.log("TEXT",text)
    props.setsearch(text);
    const val = props.searchArray.filter((question) => {
      return question.audit_question
        .toLowerCase()
        .includes(props.search.toLowerCase());
    });
    props.setquestionList(val);
  };
  return (
    <>
      <Breadcrumb title="Basic Input" parent="Form" />
      <div className="container-fluid">
        <form className="form theme-form">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Title</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Type your title in Placeholder"
                      value={question.question_title}
                      onChange={(e) =>
                        setquestion({
                          ...question,
                          question_title: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-sm-3 col-form-label">Question</label>
                  <div className="col-sm-9">
                    <textarea
                      className="form-control"
                      rows="5"
                      cols="5"
                      placeholder="Default textarea"
                      value={question.audit_question}
                      onChange={(e) =>
                        setquestion({
                          ...question,
                          audit_question: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Question Type
                  </label>
                  <div className="col-sm-9">
                    <fieldset
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      id="group1"
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio1"
                          type="radio"
                          name="group1"
                          value="1"
                          checked={
                            question.question_type === "1" ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              question_type: e.target.value,
                            })
                          }
                        />
                        <label for="radio1">Yes/No</label>
                      </div>

                      <div class="radio radio-primary">
                        <input
                          id="radio2"
                          type="radio"
                          name="group1"
                          value="2"
                          checked={
                            question.question_type === "2" ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              question_type: e.target.value,
                            })
                          }
                        />
                        <label for="radio2">Count</label>
                      </div>

                      <div class="radio radio-primary">
                        <input
                          id="radio3"
                          type="radio"
                          name="group1"
                          value="5"
                          checked={
                            question.question_type === "5" ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              question_type: e.target.value,
                            })
                          }
                        />
                        <label for="radio3">YES/NO/NA</label>
                      </div>

                      <div class="radio radio-primary">
                        <input
                          id="radio4"
                          type="radio"
                          name="group1"
                          value="4"
                          checked={
                            question.question_type === "4" ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              question_type: e.target.value,
                            })
                          }
                        />
                        <label for="radio4">Check</label>
                      </div>
                    </fieldset>

                    

                    {question.question_type === "1" && (
                      <div>
                        <fieldset
                          id="1.0"
                          style={{
                            display: "flex",width:"100px",alignItems:"center",justifyContent:"space-evenly"
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="1.0.0"
                              type="radio"
                              name="1.0"
                              value="1"
                              checked={props.yes_no === 1 ? true : false}
                              onChange={(e) => {
                                setquestion({
                                  ...question,
                                  action_taken_on_yes: Number(e.target.value),
                                });
                                props.setyes_no(Number(e.target.value));
                              }}
                            />
                            <label for="1.0.0">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="1.0.1"
                              type="radio"
                              name="1.0"
                              value="2"
                              checked={props.yes_no === 2 ? true : false}
                              onChange={(e) => {
                                setquestion({
                                  ...question,
                                  action_taken_on_yes: Number(e.target.value),
                                });
                                props.setyes_no(Number(e.target.value));
                              }}
                            />
                            <label for="1.0.1">No</label>
                          </div>
                        </fieldset>

                        {props.yes_no === 2 ? (
                          <div>
                            <div className="shadow">
                              <h5>Next Question</h5>
                              <input
                                type="text"
                                className="form-control shadow"
                                placeholder="Search"
                                value={props.search}
                                onChange={(e) => {
                                  handleSearch(e.target.value);
                                }}
                              />
                              <div
                                style={{ height: "200px", overflowY: "scroll" }}
                              >
                                {props.questionList &&
                                  props.questionList.map((row, index) => (
                                    <div
                                      style={{
                                        margin: "1rem 0rem",
                                        backgroundColor:
                                          question.go_question_id ===
                                          row.question_id
                                            ? "#303e5a"
                                            : "#fff",
                                        color:
                                          question.go_question_id ===
                                          row.question_id
                                            ? "#fff"
                                            : "#000",
                                        padding: 10,
                                      }}
                                      className="shadow"
                                      key={index}
                                      onClick={() => {
                                        handlequestion(row.question_id);
                                      }}
                                    >
                                      <label>{row.audit_question}</label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div style={{ margin: "1rem 0rem" }}>
                              <h5>Show Score</h5>
                              <input
                                type="number"
                                className="form-control shadow"
                                value={question.set_range_2}
                                onChange={(e) =>
                                  setquestion({
                                    ...question,
                                    set_range_2: parseInt(e.target.value),
                                  })
                                }
                              />
                            </div>
                            <div style={{ margin: "1rem 0rem" }}>
                              <h5>Show Remark</h5>
                              <input
                                type="text"
                                className="form-control shadow"
                                value={question.remark_no}
                                onChange={(e) =>
                                  setquestion({
                                    ...question,
                                    remark_no: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>
                                <h5>
                                  Action{" "}
                                  <p style={{ color: "gray" }}>
                                    (Selected item will not shown)
                                  </p>
                                </h5>
                                <fieldset id="1.1.0">
                                  <div class="radio radio-primary">
                                    <input
                                      id="1.1.1"
                                      type="radio"
                                      name="1.1.0"
                                      value="0"
                                      checked={
                                        question.action_taken_no == 0
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        // console.log(e.target.value)
                                        setquestion({
                                          ...question,
                                          action_taken_no: Number(
                                            e.target.value
                                          ),
                                          action_taken_on_yes: Number(
                                            e.target.value
                                          ),
                                        })
                                      }
                                    />
                                    <label for="1.1.1">Default</label>
                                  </div>
                                  <div class="radio radio-primary">
                                    <input
                                      id="1.1.2"
                                      type="radio"
                                      name="1.1.0"
                                      value="1"
                                      checked={
                                        question.action_on_no === 1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setquestion({
                                          ...question,
                                          action_on_no: Number(e.target.value),
                                          action_taken_no: 1,
                                          action_taken_on_yes: Number(
                                            e.target.value
                                          ),
                                        })
                                      }
                                    />
                                    <label for="1.1.2">Remark</label>
                                  </div>
                                  <div class="radio radio-primary">
                                    <input
                                      id="1.1.3"
                                      type="radio"
                                      name="1.1.0"
                                      value="2"
                                      checked={
                                        question.action_on_no === 2
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setquestion({
                                          ...question,
                                          action_on_no: Number(e.target.value),
                                          action_taken_no: 1,
                                          action_taken_on_yes: Number(
                                            e.target.value
                                          ),
                                        })
                                      }
                                    />
                                    <label for="1.1.3">Caputre Image</label>
                                  </div>
                                  <div class="radio radio-primary">
                                    <input
                                      id="1.1.4"
                                      type="radio"
                                      name="1.1.0"
                                      value="3"
                                      checked={
                                        question.action_on_no === 3
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setquestion({
                                          ...question,
                                          action_on_no: Number(e.target.value),
                                          action_taken_on_yes: Number(e.target.value),
                                          action_taken_no: 1,
                                        })
                                      }
                                    />
                                    <label for="1.1.4">Actionable</label>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="shadow">
                              <h5>Next Question</h5>
                              <input
                                type="text"
                                className="form-control shadow"
                                placeholder="Search"
                                value={props.search}
                                onChange={(e) => {
                                  handleSearch(e.target.value);
                                }}
                              />
                              <div
                                style={{ height: "200px", overflowY: "scroll" }}
                              >
                                {props.questionList &&
                                  props.questionList.map((row, index) => (
                                    <div
                                      style={{
                                        margin: "1rem 0rem",
                                        backgroundColor:
                                          question.go_question_id ===
                                          row.question_id
                                            ? "#303e5a"
                                            : "#fff",
                                        color:
                                          question.go_question_id ===
                                          row.question_id
                                            ? "#fff"
                                            : "#000",
                                        padding: 10,
                                      }}
                                      className="shadow"
                                      key={index}
                                      onClick={() => {
                                        handlequestion(row.question_id);
                                      }}
                                    >
                                      <label>{row.audit_question}</label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div style={{ margin: "1rem 0rem" }}>
                              <h5>Show Score</h5>
                              <input
                                type="number"
                                className="form-control shadow"
                                value={question.set_range_1}
                                onChange={(e) =>
                                  setquestion({
                                    ...question,
                                    set_range_1: parseInt(e.target.value),
                                  })
                                }
                              />
                            </div>
                            <div style={{ margin: "1rem 0rem" }}>
                              <h5>Show Remark</h5>
                              <input
                                type="text"
                                className="form-control shadow"
                                value={question.remark_yes}
                                onChange={(e) =>
                                  setquestion({
                                    ...question,
                                    remark_yes: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>
                                <h5>
                                  Action{" "}
                                  <p style={{ color: "gray" }}>
                                    (Selected item will not shown)
                                  </p>
                                </h5>
                                <fieldset id="1.2.0">
                                  <div class="radio radio-primary">
                                    <input
                                      id="1.2.1"
                                      type="radio"
                                      name="1.2.0"
                                      value="0"
                                      checked={
                                        question.action_taken_on_yes == 0
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        // console.log(e.target.value)
                                        setquestion({
                                          ...question,
                                          action_taken_on_yes: Number(
                                            e.target.value
                                          ),
                                          action_taken_no: 0,
                                        })
                                      }
                                    />
                                    <label for="1.2.1">Default</label>
                                  </div>
                                  <div class="radio radio-primary">
                                    <input
                                      id="1.2.2"
                                      type="radio"
                                      name="1.2.0"
                                      value="1"
                                      checked={
                                        question.action_on_yes === 1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setquestion({
                                          ...question,
                                          action_on_yes: Number(e.target.value),
                                          action_taken_on_yes: 1,
                                          action_taken_no: 0,
                                        })
                                      }
                                    />
                                    <label for="1.2.2">Actionable</label>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {question.question_type === "2" && (
                      <div>
                        <h5>Action</h5>
                        <div>
                          <fieldset id="1.2.0">
                            <div class="radio radio-primary">
                              <input
                                id="1.2.1"
                                type="radio"
                                name="1.2.0"
                                value="0"
                                checked={
                                  question.count_type == 0 ? true : false
                                }
                                onChange={(e) =>
                                  // console.log(e.target.value)
                                  setquestion({
                                    ...question,
                                    count_type: Number(e.target.value),
                                  })
                                }
                              />
                              <label for="1.2.1">Default</label>
                            </div>
                            <div class="radio radio-primary">
                              <input
                                id="1.2.2"
                                type="radio"
                                name="1.2.0"
                                value="2"
                                checked={
                                  question.count_type === 2 ? true : false
                                }
                                onChange={(e) =>
                                  setquestion({
                                    ...question,
                                    count_type: Number(e.target.value),
                                  })
                                }
                              />
                              <label for="1.2.2">
                                {
                                  "Greater then 0 (3a - 3b > 0 only then actionable appear)"
                                }
                              </label>
                            </div>
                            <div class="radio radio-primary">
                              <input
                                id="1.2.3"
                                type="radio"
                                name="1.2.0"
                                value="1"
                                checked={
                                  question.count_type === 1 ? true : false
                                }
                                onChange={(e) =>
                                  setquestion({
                                    ...question,
                                    count_type: Number(e.target.value),
                                  })
                                }
                              />
                              <label for="1.2.3">{"Previous Qty > 0"}</label>
                            </div>
                          </fieldset>
                          {question.count_type === 2 && (
                            <div className="shadow">
                              <h5>Previous Question</h5>
                              <input
                                type="text"
                                className="form-control shadow"
                                placeholder="Search"
                                value={props.search}
                                onChange={(e) => {
                                  handleSearch(e.target.value);
                                }}
                              />
                              <div
                                style={{ height: "200px", overflowY: "scroll" }}
                              >
                                {props.questionList &&
                                  props.questionList.map((row, index) => (
                                    <div
                                      style={{
                                        margin: "1rem 0rem",
                                        backgroundColor:
                                          question.count_previous_question_id ===
                                          row.question_id
                                            ? "#303e5a"
                                            : "#fff",
                                        color:
                                          question.count_previous_question_id ===
                                          row.question_id
                                            ? "#fff"
                                            : "#000",
                                        padding: 10,
                                      }}
                                      className="shadow"
                                      key={index}
                                      onClick={() => {
                                        setquestion({
                                          ...question,
                                          count_previous_question_id:
                                            row.question_id,
                                        });
                                      }}
                                    >
                                      <label>{row.audit_question}</label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                          {question.count_type === 1 && (
                            <div className="shadow">
                              <h5>Previous Question</h5>
                              <input
                                type="text"
                                className="form-control shadow"
                                placeholder="Search"
                                value={props.search}
                                onChange={(e) => {
                                  handleSearch(e.target.value);
                                }}
                              />
                              <div
                                style={{ height: "200px", overflowY: "scroll" }}
                              >
                                {props.questionList &&
                                  props.questionList.map((row, index) => (
                                    <div
                                      style={{
                                        margin: "1rem 0rem",
                                        backgroundColor:
                                          question.go_to_count_question_id ===
                                          row.question_id
                                            ? "#303e5a"
                                            : "#fff",
                                        color:
                                          question.go_to_count_question_id ===
                                          row.question_id
                                            ? "#fff"
                                            : "#000",
                                        padding: 10,
                                      }}
                                      className="shadow"
                                      key={index}
                                      onClick={() => {
                                        setquestion({
                                          ...question,
                                          go_to_count_question_id:
                                            row.question_id,
                                        });
                                      }}
                                    >
                                      <label>{row.audit_question}</label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Check Type</label>
                  <div className="col-sm-9">
                    <fieldset
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                      id="checkType"
                    >
                      <div class="radio radio-primary">
                        <input
                          id="checkType1"
                          type="radio"
                          name="checkType"
                          value="1"
                          checked={
                            question.check_box_type === 1 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              check_box_type: Number(e.target.value),
                            })
                          }
                        />
                        <label for="checkType1">Yes</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="checkType2"
                          type="radio"
                          name="checkType"
                          value="0"
                          checked={
                            question.check_box_type === 0 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              check_box_type: Number(e.target.value),
                            })
                          }
                        />
                        <label for="checkType2">No</label>
                      </div>
                    </fieldset>
                  {
                    question.check_box_type===1&&(
                      <input
                      className="form-control"
                      type="text"
                      placeholder="One,Two,Three"
                      value={question.check_box_value}
                      onChange={(e) =>
                        setquestion({
                          ...question,
                          check_box_value: e.target.value,
                        })
                      }
                      required
                    />
                    )
                  }
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Score Range</label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group2"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio5"
                          type="radio"
                          name="group2"
                          value="0"
                          checked={question.score_range === 0 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              score_range: parseInt(e.target.value),
                            })
                          }
                        />
                        <label for="radio5">No</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radio6"
                          type="radio"
                          name="group2"
                          value="1"
                          checked={question.score_range === 1 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              score_range: parseInt(e.target.value),
                            })
                          }
                        />
                        <label for="radio6">Yes</label>
                      </div>
                    </fieldset>
                    {question.score_range === 1 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "80%",
                        }}
                      >
                        <div>
                          <label>Range form </label>
                          <input
                            type="text"
                            className="form-control shadow"
                            value={question.score_range_from}
                          />
                        </div>
                        <div>
                          <label>Range to </label>
                          <input
                            type="text"
                            className="form-control shadow"
                            value={question.score_range_to}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Remark</label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group3"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio7"
                          type="radio"
                          name="group3"
                          value="1"
                          checked={question.remark === "1" ? true : false}
                          onChange={(e) =>
                            setquestion({ ...question, remark: e.target.value })
                          }
                        />
                        <label for="radio7">Yes</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radio8"
                          type="radio"
                          name="group3"
                          value="0"
                          checked={question.remark === "0" ? true : false}
                          onChange={(e) =>
                            setquestion({ ...question, remark: e.target.value })
                          }
                        />
                        <label for="radio8">No</label>
                      </div>
                    </fieldset>
                    {question.remark === "1" ? (
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type your remark"
                        value={question.actionsble_remark}
                        onChange={(e) =>
                          setquestion({
                            ...question,
                            actionsble_remark: e.target.value,
                          })
                        }
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Image Capture
                  </label>
                  <div className="col-sm-9">
                    <fieldset
                      id="200"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="22"
                          type="radio"
                          name="200"
                          value="1"
                          checked={question.image_capture == 1 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              image_capture: Number(e.target.value),
                            })
                          }
                        />
                        <label for="22">Yes</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="21"
                          type="radio"
                          name="200"
                          value="0"
                          checked={question.image_capture == 0 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              image_capture: Number(e.target.value),
                            })
                          }
                        />
                        <label for="21">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Branch Manager Assign
                  </label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group4"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio22"
                          type="radio"
                          name="group4"
                          value="1"
                          checked={
                            question.bm_actionable_assignee == 1 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              bm_actionable_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio22">Yes</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radio21"
                          type="radio"
                          name="group4"
                          value="0"
                          checked={
                            question.bm_actionable_assignee == 0 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              bm_actionable_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio21">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    RMM Action Assign
                  </label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group7"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio13"
                          type="radio"
                          name="group7"
                          value="1"
                          checked={
                            question.rmm_actionable_assignee == 1 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              rmm_actionable_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio13">Yes</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radio14"
                          type="radio"
                          name="group7"
                          value="0"
                          checked={
                            question.rmm_actionable_assignee == 0 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              rmm_actionable_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio14">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    ATM coordinator Assign
                  </label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group17"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio11"
                          type="radio"
                          name="group17"
                          value="1"
                          checked={
                            question.atm_cordinator_assignee == 1 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              atm_cordinator_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio11">Yes</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radio10"
                          type="radio"
                          name="group17"
                          value="0"
                          checked={
                            question.atm_cordinator_assignee == 0 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              atm_cordinator_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio10">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Admin Assign
                  </label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group8"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio16"
                          type="radio"
                          name="group8"
                          value="1"
                          checked={question.admin_assignee == 1 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              admin_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio16">Yes</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radio17"
                          type="radio"
                          name="group8"
                          value="0"
                          checked={question.admin_assignee == 0 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              admin_assignee: Number(e.target.value),
                            })
                          }
                        />
                        <label for="radio17">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Branch type</label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group5"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio18"
                          type="radio"
                          name="group5"
                          value="0"
                          checked={question.type === 0 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              type: parseInt(e.target.value),
                            })
                          }
                        />
                        <label for="radio18">Old</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="19"
                          type="radio"
                          name="group5"
                          value="1"
                          checked={question.type === 1 ? true : false}
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              type: parseInt(e.target.value),
                            })
                          }
                        />
                        <label for="19">New</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    ATM Question Type
                  </label>
                  <div className="col-sm-9">
                    <fieldset
                      id="group15"
                      style={{
                        display: "flex",
                        width: 100,
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="radio radio-primary">
                        <input
                          id="radio19"
                          type="radio"
                          name="group15"
                          value="0"
                          checked={
                            question.atm_question_type === 0 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              atm_question_type: parseInt(e.target.value),
                            })
                          }
                        />
                        <label for="radio19">No</label>
                      </div>
                      <div class="radio radio-primary">
                        <input
                          id="radio20"
                          type="radio"
                          name="group15"
                          value="1"
                          checked={
                            question.atm_question_type === 1 ? true : false
                          }
                          onChange={(e) =>
                            setquestion({
                              ...question,
                              atm_question_type: parseInt(e.target.value),
                            })
                          }
                        />
                        <label for="radio20">Yes</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="col-sm-9 offset-sm-3">
              <button
                className="btn btn-primary mr-1"
                type="button"
                onClick={() => props.handleQuestionUpdate(question.question_id)}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
