import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import apiEndPoints from "../../../utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";
import "./addEmploye.css";

const AddEmploye = () => {
  const [employeData, setEmployeData] = useState({
    emp_code: "",
    phone: "",
    name: "",
    email: "",
    password: "",
    emp_role_id: "",
  });
  const [employe_role_id_list, setEmploye_role_id_list] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [csvScreen, setCsvScreen] = useState(false);
  const [inputData, setInputData] = useState({
    employee_roal_type: "",
    csv_file: "",
  });
  const [formErr, setFormErr] = useState({});
  const [resp, setResp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getEmployeeRoleId();
  }, []);

  function emailValidationChk() {
    let formErrors = {};
    let formIsValid = true;
    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!employeData.emp_code) {
      formIsValid = false;
      formErrors["emp_code"] = "Enter Employee Code";
    }
    if (!employeData.phone || employeData.phone.length != 10) {
      formIsValid = false;
      formErrors["phone"] = "Enter Phone number";
    }
    if (!employeData.name) {
      formIsValid = false;
      formErrors["name"] = "Enter Employee Name";
    }
    if (!employeData.password) {
      formIsValid = false;
      formErrors["password"] = "Enter Password";
    }
    if (!employeData.emp_role_id) {
      formIsValid = false;
      formErrors["emp_role_id"] = "Select Employee role";
    }
    if (!employeData.email || emailValid.test(employeData.email) === false) {
      formIsValid = false;
      formErrors["email"] = "Enter valid Email!";
    }
    setFormErrors(formErrors);
    return formIsValid;
  }

  const getEmployeeRoleId = async () => {
    try {
      const response = await apiCall("GET", apiEndPoints.GETEMPLOYEEROLEID);
      if (response.status === 200) {
        setEmploye_role_id_list(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emp_r_id = employe_role_id_list.map((x) => {
    return (
      <>
        <option value={x.user_role_id}>{x.role_name}</option>;
      </>
    );
  });

  const employeDataInsert = async () => {
    console.log("employeData", employeData);
    console.log("emailValidationChk", emailValidationChk);
    console.log("formErros", formErrors);
    if (emailValidationChk()) {
      try {
        const userToken = localStorage.getItem("AuthToken");
        setDefaultHeader("token", userToken);
        const response = await apiCall(
          "POST",
          apiEndPoints.EMPLYEEREGISTER,
          employeData
        );
        console.log("EMPLYEEREGISTER", response);
        if (response.status === 200) {
          //   window.location.href = "/dashboard/employeList";
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  function cvsScreenValidation() {
    let formErrors = {};
    let formIsValid = true;

    if (!inputData.employee_roal_type) {
      formIsValid = false;
      formErrors["employee_roal_type"] = "Select Employement Type";
    }
    if (!inputData.csv_file) {
      formIsValid = false;
      formErrors["csv_file"] = "Select CSV File";
    }
    setFormErr(formErrors);
    return formIsValid;
  }
  const rmm_csv = [
    "branch_code",
    "rmm_name",
    "rmm_emp_id",
    "rmm_mail_id",
    "rmm_phone",
  ];
  const rmh_csv = [
    "branch_code",
    "rmh_name",
    "rmh_emp_id",
    "rmh_mail_id",
    "rmh_phone",
  ];
  const ah_csv = [
    "branch_code",
    "ah_name",
    "ah_emp_id",
    "ah_mail_id",
    "ah_phone",
  ];

  const handleCsvDownload = () => {
    console.log("download btn call");
    var hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," +
      encodeURI(
        inputData.employee_roal_type === 3
          ? rmm_csv
          : inputData.employee_roal_type === 4
          ? ah_csv
          : inputData.employee_roal_type === 5
          ? rmh_csv
          : ""
      );
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `${
      inputData.employee_roal_type == 3
        ? "RMM"
        : inputData.employee_roal_type == 4
        ? "AH"
        : inputData.employee_roal_type == 5
        ? "RMH"
        : ""
    } Sample`;
    hiddenElement.click();
  };

  const handleCsvSubmit = async () => {
    setIsLoading(true);
    console.log("inputData ==>", inputData);
    if (cvsScreenValidation()) {
      try {
        let generateReportData = new FormData();
        generateReportData.append("csvfile", inputData.csv_file);
        generateReportData.append(
          "employee_type",
          inputData.employee_roal_type
        );
        const userToken = localStorage.getItem("AuthToken");
        setDefaultHeader("token", userToken);
        const response = await apiCall(
          "POST",
          apiEndPoints.GENERATENEWREPORT,
          generateReportData
        );
        console.log("GENERATENEWREPORT csv res==>", response);
        if (response.status === 200) {
          //   window.location.href = "/dashboard/employeList";
          // window.location.reload();
          setResp(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };
  console.log("inputData", inputData);

  return (
    <>
      <Breadcrumb title="Employee" parent="Add Employee" />
      {/* <button className="add-emp-btn" onClick={(e) => setCsvScreen(true)}>
        Upload CSV
      </button> */}
      {inputData.employee_roal_type ? (
        <a className="add-emp-btn" download onClick={handleCsvDownload}>
          <span className="download_csv_txt">
            Download Sample
            {inputData.employee_roal_type === 3
              ? " RMM"
              : inputData.employee_roal_type === 4
              ? " AH"
              : inputData.employee_roal_type === 5
              ? " RMH"
              : ""}
            CSV
          </span>
        </a>
      ) : (
        ""
      )}
      <div className="container-fluid">
        {csvScreen == false ? (
          <form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">
                      Employee Code
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Employee Code"
                        value={employeData.emp_code}
                        onChange={(e) =>
                          setEmployeData({
                            ...employeData,
                            emp_code: e.target.value,
                          })
                        }
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {!employeData.emp_code &&
                        formErrors &&
                        formErrors.emp_code
                          ? formErrors.emp_code
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">
                      Employee Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Employee Name"
                        value={employeData.name}
                        onChange={(e) =>
                          setEmployeData({
                            ...employeData,
                            name: e.target.value,
                          })
                        }
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {!employeData.name && formErrors && formErrors.name
                          ? formErrors.name
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Employee Email"
                        value={employeData.email}
                        onChange={(e) =>
                          setEmployeData({
                            ...employeData,
                            email: e.target.value,
                          })
                        }
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {!employeData.email ||
                        (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          employeData.email
                        ) === false &&
                          formErrors &&
                          formErrors.email)
                          ? formErrors.email
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">Password</label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        value={employeData.password}
                        onChange={(e) =>
                          setEmployeData({
                            ...employeData,
                            password: e.target.value,
                          })
                        }
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {!employeData.password &&
                        formErrors &&
                        formErrors.password
                          ? formErrors.password
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">Phone</label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        maxLength={10}
                        type="text"
                        placeholder="Phone"
                        value={employeData.phone}
                        onChange={(e) =>
                          setEmployeData({
                            ...employeData,
                            phone: e.target.value,
                          })
                        }
                        onKeyPress={(event) => {
                          if (/^[a-z]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {!employeData.phone ||
                        (employeData.phone.length != 10 &&
                          formErrors &&
                          formErrors.phone)
                          ? formErrors.phone
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Employee Role
                    </label>
                    <div className="col-sm-9">
                      <select
                        id=""
                        className="form-control"
                        value={employeData.emp_role_id}
                        onChange={(e) =>
                          setEmployeData({
                            ...employeData,
                            emp_role_id: Number(e.target.value),
                          })
                        }
                      >
                        <option value="">select Role-</option>
                        {emp_r_id}
                      </select>
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {!employeData.emp_role_id &&
                        formErrors &&
                        formErrors.emp_role_id
                          ? formErrors.emp_role_id
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="col-sm-9 offset-sm-3">
                <button
                  className="btn btn-primary mr-1"
                  type="button"
                  onClick={() => employeDataInsert()}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        ) : (
          <form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">
                      Employee Type
                    </label>
                    <div className="col-sm-9">
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          value={inputData.employee_roal_type}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              employee_roal_type: Number(e.target.value),
                            })
                          }
                        >
                          <option value="">Select Employee Type-</option>
                          <option value="5">RMH</option>
                          <option value="4">AH</option>
                          <option value="3">RMM</option>
                        </select>
                        <span
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          {!inputData.employee_roal_type &&
                          formErr &&
                          formErr.employee_roal_type
                            ? formErr.employee_roal_type
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">
                      Select CSV File
                    </label>
                    <div className="col-sm-9">
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              csv_file: e.target.files[0],
                            })
                          }
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          {!inputData.csv_file && formErr && formErr.csv_file
                            ? formErr.csv_file
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <span style={{ color: "green" }}>
                        {resp ? "Employee Update Successfully" : ""}
                      </span>
                    </div>
                    <button
                      className="csv_back_btn"
                      onClick={() => setCsvScreen(false)}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="col-sm-9 offset-sm-3">
                <button
                  className="btn btn-primary mr-1"
                  type="button"
                  onClick={handleCsvSubmit}
                >
                  {isLoading ? "Please wait while Updating..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default AddEmploye;
