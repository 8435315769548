import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb'
import Alert from '../../../components/Alert'
import { apiCall, setDefaultHeader } from '../../../utils/httpClient'
import apiEndPoints from '../../../utils/apiEndPoints'
export default function BranchComponent(props) {
    const { branch, setbranch, UpdateBranch, message } = props
    const [cityList, setcityList] = useState([])
    var CityName = branch.city;
    var selected = (branch.city === CityName) ? true : false;
    const CityList = async () => {
        const userToken = localStorage.getItem('AuthToken')
        await setDefaultHeader('token', userToken)
        const params = { type: 1 }
        const response = await apiCall('POST', apiEndPoints.City_List, params)
        if (response.status === 200) {
            setcityList(response.data.data)
        }
    }
    useEffect(() => {
        CityList()
        DisplayCityList()
    }, [])
    const DisplayCityList = async () => {
        CityName =
            console.log("cityname", CityName)
    }


    return (
        <div className="container-fluid">
            {
                message &&
                <Alert message={message} />

            }
            {!branch && <h2>Loading...</h2>}
            <form className="form theme-form">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Branch Name"
                                        value={branch.branch_name}
                                        onChange={e => setbranch({ ...branch, branch_name: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-sm-3 col-form-label">Address</label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" rows="5" cols="5" placeholder="Adress"
                                        value={branch.branch_address}
                                        onChange={e => setbranch({ ...branch, branch_address: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">City</label>
                                <div className="col-sm-9">
                                    <select id="" className="form-control" 
                                    value={branch.city}
                                    onChange={e => setbranch({ ...branch, city: e.target.value })}
                                    >
                                        {cityList.map(value =>
                                            <option value={value.city_id}
                                             {...branch.city==value.city_id ? 'selected' : ''}>
                                                 {value.city_name} 
                                                 {/* {console.log(value)} */}
                                            </option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">District</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="District"
                                        value={branch.district}
                                        onChange={e => setbranch({ ...branch, district: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-sm-3 col-form-label">Pincode</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Pincode"
                                        value={branch.pin_code}
                                        onChange={e => setbranch({ ...branch, pin_code: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Geographical State</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Geographical State"
                                        value={branch.geographical_state}
                                        onChange={e => setbranch({ ...branch, geographical_state: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Status</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Status"
                                        value={branch.status}
                                        onChange={e => setbranch({ ...branch, status: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">RBI Class</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="RBI Class"
                                        value={branch.rbi_class}
                                        onChange={e => setbranch({ ...branch, rbi_class: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Cluster</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Cluster"
                                        value={branch.cluster}
                                        onChange={e => setbranch({ ...branch, cluster: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Zone</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Zone"
                                        value={branch.zone}
                                        onChange={e => setbranch({ ...branch, zone: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Circle</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Circle"
                                        value={branch.circle}
                                        onChange={e => setbranch({ ...branch, circle: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Region</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Region"
                                        value={branch.region}
                                        onChange={e => setbranch({ ...branch, region: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Manager</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Manager"
                                        value={branch.branch_manager}
                                        onChange={e => setbranch({ ...branch, branch_manager: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Manager Employee Code</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Manager Employee Code"
                                        value={branch.bm_emp_code}
                                        onChange={e => setbranch({ ...branch, bm_emp_code: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Manager Number</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Manager Number"
                                        value={branch.bm_no}
                                        onChange={e => setbranch({ ...branch, bm_no: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Head</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Head"
                                        value={branch.bbh_head}
                                        onChange={e => setbranch({ ...branch, bbh_head: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Head Region</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Head Region"
                                        value={branch.bbh_region}
                                        onChange={e => setbranch({ ...branch, bbh_region: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Head Employee Code</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Head Employee Code"
                                        value={branch.bbh_emp_code}
                                        onChange={e => setbranch({ ...branch, bbh_emp_code: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Regional Head</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Regional Head"
                                        value={branch.regional_head}
                                        onChange={e => setbranch({ ...branch, regional_head: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Regional Head Employee Code</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Regional Head Employee Code"
                                        value={branch.regional_emp_code}
                                        onChange={e => setbranch({ ...branch, regional_emp_code: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Zonal Head</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Zonal Head"
                                        value={branch.zonal_head}
                                        onChange={e => setbranch({ ...branch, zonal_head: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Zonal Head Employee Code</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Zonal Head Employee Code"
                                        value={branch.zonal_emp_code}
                                        onChange={e => setbranch({ ...branch, zonal_emp_code: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Circle Head</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Circle Head"
                                        value={branch.circle_head}
                                        onChange={e => setbranch({ ...branch, circle_head: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Circle Head Employee Code</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Circle Head Employee Code"
                                        value={branch.circle_head_empcode}
                                        onChange={e => setbranch({ ...branch, circle_head_empcode: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Circle State Head</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Circle State Head"
                                        value={branch.circle_state_head}
                                        onChange={e => setbranch({ ...branch, circle_state_head: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Circle State Head Employee Code</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Circle State Head Employee Code"
                                        value={branch.circle_state_empcode}
                                        onChange={e => setbranch({ ...branch, circle_state_empcode: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label"> RMH Name</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="RMH Name"
                                        value={branch.rmh_name}
                                        onChange={e => setbranch({ ...branch, rmh_name: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label"> RMM Name</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="RMM Name"
                                        value={branch.rmm_name}
                                        onChange={e => setbranch({ ...branch, rmm_name: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">RMM ID</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="RMM ID"
                                        value={branch.rmm_id}
                                        onChange={e => setbranch({ ...branch, rmm_id: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">RMM Email</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="RMM Email"
                                        value={branch.rmm_email}
                                        onChange={e => setbranch({ ...branch, rmm_email: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">RMM Phone</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="RMM Phone"
                                        value={branch.rmm_phone}
                                        onChange={e => setbranch({ ...branch, rmm_phone: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">AH Name</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="AH Name"
                                        value={branch.ah_name}
                                        onChange={e => setbranch({ ...branch, ah_name: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">AH Employee code</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="AH Employee code"
                                        value={branch.ah_empcode}
                                        onChange={e => setbranch({ ...branch, ah_empcode: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">AH Email</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="AH Email"
                                        value={branch.ah_mail}
                                        onChange={e => setbranch({ ...branch, ah_mail: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">AH Phone</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="AH Phone"
                                        value={branch.ah_phone}
                                        onChange={e => setbranch({ ...branch, ah_phone: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label"> Branch Type</label>
                                <div className="col-sm-9">
                                <select id="" className="form-control" 
                                    value={branch.branch_type}
                                    onChange={e => setbranch({ ...branch, branch_type: Number(e.target.value) })}
                                    >
                                            <option value={0}>Old Branch</option>
                                            <option value={1}>New Branch</option>
                                    </select>
                                    {/* <input className="form-control" type="text"
                                        placeholder="Branch Type"
                                        value={branch.branch_type}
                                        onChange={e => setbranch({ ...branch, branch_type: e.target.value })}
                                    /> */}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Digital Screen</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text"
                                        placeholder="Digital Screen"
                                        value={branch.digital_screen}
                                        onChange={e => setbranch({ ...branch, digital_screen: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="col-sm-9 offset-sm-3">
                        <button className="btn btn-primary mr-1" type="button" onClick={() => UpdateBranch()}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
