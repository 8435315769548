import React from 'react';
import { Link } from 'react-router-dom'
const Dashboard = ()=>{
    return(
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-lg-4 col-xlg-8">
                  <div className="card card-hover">
                    <div className="box bg-success text-center">
                    <Link to="/dashboard/branchlist">
                      <h1 className="font-light text-white">
                        <i className="fa fa-building " />
                      </h1>
                      <h5 className="m-b-0 m-t-3 text-white">
                      </h5>
                      <h6 className="text-white">Total Branch</h6>
                      {/* <h3 className="text-white">50</h3> */}
                      </Link>
                    </div>
                  </div>
              </div>
              <div className="col-md-6 col-lg-4 col-xlg-8">
                <a>
                  <div className="card card-hover">
                    <div className="box bg-warning text-center">
                    <Link to="/dashboard/QuestionList">
                      <h1 className="font-light text-white">
                        <i className="fa fa-question-circle" />
                      </h1>
                      <h5 className="m-b-0 m-t-3 text-white">
                      </h5>
                      <h6 className="text-white">Total Question</h6>
                      {/* <h3 className="text-white">50</h3> */}
                      </Link>
                    </div>
                  </div>
                </a>
              </div>
              </div>
            {/* <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                     </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
      
    )
}

export default Dashboard;